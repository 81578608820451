import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { defaultCountries } from 'react-international-phone';

import { GOOGLE_MAPS_API_KEY } from '../../../constants/google';
import { getTranslated } from '../../../utils/translations';
import PhoneLocalInput from '../../Inputs/PhoneLocalInput';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';

const CompanyInformation = ({
  className,
  formDetails,
  formData,
  handleFormDataChange,
  handlePlaceSelected,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: { types: ['address'] },
    onPlaceSelected: handlePlaceSelected,
  });

  return (
    <div className={className}>
      <SelectInput
        id="alreadyClient"
        name="alreadyClient"
        label={t('register.already-client')}
        value={formData.alreadyClient}
        updateValue={(value) => handleFormDataChange('alreadyClient', value)}
        options={[
          { value: '1', label: t('register.yes') },
          { value: '0', label: t('register.no') },
        ]}
        errors={errors?.alreadyClient}
      />
      {formData.alreadyClient === '1' ? (
        <TextInput
          id="code"
          name="code"
          placeholder={t('register.client-code')}
          value={formData.code}
          updateValue={(value) => handleFormDataChange('code', value)}
          errors={errors?.code}
        />
      ) : (
        <>
          <TextInput
            id="name_register"
            name="name_register"
            placeholder={t('name_required')}
            value={formData.name_register}
            updateValue={(value) => handleFormDataChange('name_register', value)}
            errors={errors?.name_register}
          />
          <TextInput
            id="surname_register"
            name="surname_register"
            placeholder={t('surname_required')}
            value={formData.surname_register}
            updateValue={(value) => handleFormDataChange('surname_register', value)}
            errors={errors?.surname_register}
          />
          <TextInput
            id="email"
            name="email"
            placeholder={t('indirizzo_email_required')}
            value={formData.email}
            updateValue={(value) => handleFormDataChange('email', value)}
            errors={errors?.email}
          />
          <PhoneLocalInput
            value={formData.mobile_no}
            onChange={(value, meta) => {
              handleFormDataChange('mobile_no', value);
            }}
            placeholder={t('telefono_required')}
            errors={errors?.mobile_no}
          />
          <TextInput
            id="business_name"
            name="business_name"
            placeholder={t('reglone_societe_required')}
            value={formData.business_name}
            updateValue={(value) => handleFormDataChange('business_name', value)}
            errors={errors?.business_name}
          />
          <TextInput
            id="vat_number"
            name="vat_number"
            placeholder={t('partial_vat_required')}
            value={formData.vat_number}
            updateValue={(value) => handleFormDataChange('vat_number', value)}
            errors={errors?.vat_number}
          />
          <TextInput
            id="address"
            name="address"
            placeholder={t('indirizzo_required')}
            value={formData.address}
            updateValue={(value) => handleFormDataChange('address', value)}
            errors={errors?.address}
            forwardedRef={ref}
          />
          <TextInput
            id="city"
            name="city"
            placeholder={t('city_required')}
            value={formData.city}
            updateValue={(value) => handleFormDataChange('city', value)}
            errors={errors?.city}
          />
          <TextInput
            id="postal_code"
            name="postal_code"
            placeholder={t('cap_required')}
            value={formData.postal_code}
            updateValue={(value) => handleFormDataChange('postal_code', value)}
            errors={errors?.postal_code}
          />
          <TextInput
            id="province"
            name="province"
            placeholder={t('province_required')}
            value={formData.province}
            updateValue={(value) => handleFormDataChange('province', value)}
            errors={errors?.province}
          />
          <SelectInput
            id="country"
            name="country"
            value={formData.country}
            updateValue={(value) => {
              const c = defaultCountries.find((c) => c[0] === value);
              if (c) {
                handleFormDataChange('country', c[0]);
                handleFormDataChange('country_code', c[1].toUpperCase());
              }
            }}
            options={defaultCountries.map((c) => ({ value: c[0], label: c[0] }))}
            errors={errors?.village}
          />
          <SelectInput
            id="reason_for_registration"
            name="reason_for_registration"
            label={t('register.registration-reason')}
            value={formData.reason_for_registration}
            updateValue={(value) => handleFormDataChange('reason_for_registration', value)}
            options={(formDetails?.reason_for_registration || []).map((r) => ({
              value: r.key,
              label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
            }))}
            errors={errors?.reason_for_registration}
          />
        </>
      )}
    </div>
  );
};

export default CompanyInformation;
